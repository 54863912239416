





























































@import "../less/vars.less";
@import "../less/mixins.less";
@import (reference) "../less/atoms.less";

.DbWhiteWrapper {
	position: relative;
	background-color: @swatches[bgWhite];
	
	&__logo {
		[showBorders2] & {
			background-color: fade( red, 10 );				
		}
		position: absolute;
		top: 2.5vw; left: 50%;
		transform: translateX( -50% );
		width: 15vw; height: 5vw;
		overflow: hidden;			
		text-indent: -100em;
		z-index: 10;
		
		background-image: url(../assets/images/logo.png);
		background-size: contain;
		background-position: center top;
		background-repeat: no-repeat;
		
		@media @mediaQuery__md {
			top: 10vw;
			width: 50vw;
			height: 10vw;
		}
		@media @mediaQuery__dt {
			
		}
	}
}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
