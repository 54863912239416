













































































































@import "../less/vars.less";
@import "../less/mixins.less";
//@import (reference) "../less/atoms.less";

.DbRow {
	position: relative;
	
	[showBorders2] & {
		background-color: fade( red, 20 );
		outline: 1px solid fade( red, 25 ); 
	}
	
	&:last-child {
		margin-bottom: -2px;			
	}
	
	&__scrollToHelper { // etwas nach oben verschoben, damit der viewport nach dem scrollen nicht ganz oben klebt
		position: absolute;
		top: -10px; left: 0;
		height: 1px; width: 100%;
		pointer-events: none;
		//background-color: fade( yellow, 20 );
	}
	&__header {
		position: relative;
		justify-content: space-between;
		align-items: center;
		
		br { display: none; }
	}
	&__body {
		//background-color: fade( red, 20 );
		
		position: relative;
	}
		
	&__toggleIcon {
		transition: transform 0.3s ease;
		display: block;
		width: 0.6em;
		height: 0.5em;
		background-color: currentColor;
		margin: 0 0.5em;
		margin-top: 0.35rem;
		opacity: 0.75;
	}
	&__header:hover &__toggleIcon {
		opacity: 1;
	}
	&__toggleIcon--isActive {
		transform: rotate( -180deg );
	}
	&__rowToggleBtn {
		position: absolute;
		top: 0; left: 0; width: 100%; height: 100%;
		opacity: 0;
		background-color: fade( red, 10 );
	}
}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
