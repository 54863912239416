@swatches : {
	bgWhite : white;
	red : rgb(229, 37, 32);
	green : rgb(3, 136, 19);
	yellow : rgb(255, 216, 0);
}
@borders : {
	color: rgb(255, 216, 0);
	width: 1px;
	strongWidth: 2px;
}

@colGap : 1rem;

@screen__xs-width : 500px;
@screen__sm-width : 715px;
@screen__md-width : 1130px;
@screen__lg-width : 1365px;
@screen__xl-width : 1950px;	
	
@mediaQuery__xs   : ~"(max-width:" @screen__xs-width ~")";
@mediaQuery__sm   : ~"(max-width:" @screen__sm-width ~")";
@mediaQuery__md   : ~"(max-width:" @screen__md-width ~")";
@mediaQuery__dt   : ~"(min-width:" @screen__md-width ~")";
@mediaQuery__lg   : ~"(min-width:" @screen__lg-width ~")";
@mediaQuery__xl   : ~"(min-width:" @screen__xl-width ~")";
