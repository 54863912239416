





























































































































































































@import "less/includes/normalize.include.less";
@import "less/vars.less";
@import "less/mixins.less";
@import "less/atoms.less";
@import "less/fonts.less";
@import "less/includes/grid.include.less"; 

body {
	.font; 
	.font--sans; 
	.font--sizeDefault;
	.font--smooth;
}
strong, b {
	.font--bold;
}
button {
	cursor: pointer;
}
h1, h2, h3, h4, h5 {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	margin: 0;
}
a {
	cursor: pointer;
	color: blue;
	color: inherit;
	text-decoration: none;
}
pre {
	@color: black;
	
	position: relative;
	padding: 0.5em;
	width: 100%;
	min-height: 2em;
	
	margin-bottom: 0.5em;
	&:first-of-type { margin-top: 1em; }
	&:last-of-type { margin-bottom: 1em; }
	
	//color: fade(@color, 65);
	tab-size: 4;
	white-space: pre;
	
	outline: 1px solid fade(@color, 25);
	background-color: fade(@color, 5);
	overflow: auto;		
	//display: none;
	
	// label
	&[name]:after {
		position: absolute;
		top: 0; right: 0;
		background-color: fade(@color, 15);
		font-size: 13px;
		line-height: 1em;
		font-family: sans-serif;
		color: fade(@color, 75);
		padding: 0.25em 0.5em;
		content: attr(name);
		font-family: inherit;
	}		
	
	&:first-child {
		margin-top: 0em;
	}
}
hr {
	border: none;
	border-top: 1px solid;
	margin: 0.5em 0;
	opacity: 0.25;
}

.app {}
.app__bodyInner {
	background-color: fade( white, 90 );
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__dt {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
