


















































































































































































































.devInfos {
	position: fixed;
	left: 0; right: 0; bottom: 0em;
	display: flex;
	justify-content: space-between;
	z-index: 1000;
	font-size: 13px;
	line-height: 13px;

	&__row {
		transition: all 0.5s ease;
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
	}
	&__row--isHidden {
		transform: translateX(110%);
	}
	&__col {
		padding: 0.25em 0.35em;
		flex-grow: 0;
		background-color: yellow;
	}

	&__toggleRowBtn {
		@width: 1.43em;

		position: relative;
		display: flex;
		flex-grow: 0;
		align-items: stretch;
		background-color: darken(yellow, 10);
		background-color: yellow;
		width: @width;
		cursor: pointer;

		div {
			//background-color: black;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			//justify-content: space-between;
			border: 0.2em solid transparent;
			//border-top-width: 0.35em;
			//border-bottom-width: 0.35em;
		}

		span {
			transition: all 0.2s ease;
			display: block;
			background-color: black;
			height: 1px;
			width: 100%;
			margin: 0;
		}
		span:nth-child(1) {
			transform: rotate(45deg) translateY(50%);
		}
		span:nth-child(2) {
			transform: rotate(-45deg) translateY(-50%);
		}
	}
	&__toggleRowBtn--isActive {
		span:nth-child(1),
		span:nth-child(2),
		span:nth-child(3) {
			margin: 1px 0;
			transform: none;
		}
	}

	&__checkbox {
		& + & {
			margin-left: 0.25em;
		}
	}
}
