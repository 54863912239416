// @ is an alias to /src

@fontPath: "@/../../assets/fonts/AlteHaasGrotesk";   

@font-face {
	font-family: 'AlteHaasGrotesk';
	src: url('@{fontPath}/AlteHaasGrotesk-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'AlteHaasGrotesk';
	src: url('@{fontPath}/AlteHaasGrotesk-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal; 
}
