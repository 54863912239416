@import "./vars.less";
@import "./mixins.less";
@import "./fonts.less";

.reset {} // reset muss ganz oben stehen, damit es leicht wieder überschrieben werden kann
.reset--button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	outline: none;
	text-align: inherit;
	cursor: pointer;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
	
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
.reset--list {
	list-style: none;
}	

.background {}

.button	{
	.reset--button;
	
	&:hover {}
	&:active {
		transform: translateY(0.1rem);
	}
}

.border {}
.border--left {
	border-left: @borders[width] solid;
}
.border--right {
	border-right: @borders[width] solid;
}
.border--bottom {
	border-bottom: @borders[width] solid;
}
.border--top {
	border-top: @borders[width] solid;
}
.border--all {
	border: @borders[width] solid;
}
.border--strong {
	border-width: @borders[strongWidth];
}

.block {
	display: block;		
}

.clip {}
.clip--triangleTop {
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.clip--triangleBottom {
	clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.clip--triangleLeft {
	clip-path: polygon(100% 100%, 100% 0, 0 50%);
}
.clip--triangleRight {
	clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.color {}
.color--yellow {
	color: @swatches[yellow];
}

.font {}
.font--sans {
	font-family: 'AlteHaasGrotesk', sans-serif;
}
.font--mono {
	font-family: 'Cutive Mono', monospace;
	font-size: 1.05em;
}
.font--smooth {
	// der monospace font ist ziemlich mager, 
	// für den habe ich deswegen das font-smoothing 
	// lieber wieder auf auto gesetzt damit er
	// etwas fetter gerendert wird
	-webkit-font-smoothing: antialiased;	
	-moz-osx-font-smoothing: antialiased;
}
.font--autoSmooth {
	// der monospace font ist ziemlich mager, 
	// für den habe ich deswegen das font-smoothing 
	// lieber wieder auf auto gesetzt damit er
	// etwas fetter gerendert wird
	-webkit-font-smoothing: auto;	
	-moz-osx-font-smoothing: auto;
}
.font--italic {
	font-style: italic;
}
.font--uppercase {
	text-transform: uppercase;
	//letter-spacing: 0.08em;
}
.font--bold {
	font-weight: 600;
}

.font--sizeHuge {			 // cardLinks on green, desktop only
	//color: red !important;
	
	font-size: 2.8vw;
	line-height: 1.1em;
	//letter-spacing: 0.01em;
	//letter-spacing: -0.01em;
	
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.font--sizeBig {			 // 
	//color: pink !important;
	
	font-size: 24px;
	line-height: 1.2em;
	letter-spacing: 0.01em;
	
	//color: cyan !important;
	@media @mediaQuery__md {}		
	@media @mediaQuery__sm {
		font-size: 20px;
	}
	@media @mediaQuery__xs {}
}
.font--sizeMedium {			 // 
	//color: blue !important;
	font-size: 18px;
	line-height: 1.3em;
	
	@media @mediaQuery__md {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.font--sizeDefault {		 // 17px
	//color: green !important;
	//font-size: 17px;
	//line-height: 1.3em;
	
	font-size: 1.25vw;
	line-height: 1.35em;
	
	@media @mediaQuery__md {
		font-size: 1.75vw;
		line-height: 1.35em;
	}
	@media @mediaQuery__sm {
		font-size: 15px;
		line-height: 1.35em;
	}
	@media @mediaQuery__xs {}
}	
.font--sizeSmall {	    	 // 
	//color: orange !important;
	font-size: 13px;
	line-height: 1.3em;

	@media @mediaQuery__md {}
}
.font--sizeMini {	    	 // 
	//color: orange !important;
	
	@media @mediaQuery__md {}	
}

.flex {  
	display: flex;
}
.flex--row {
	flex-direction: row;
}
.flex--column {
	flex-direction: column;
}
.flex--grow {
	flex-grow: 1;
}
.flex--noGrow {
	flex-grow: 1;
}
.flex--shrink {
	flex-shrink: 1;
}
.flex--left {
	justify-content: flex-start;
}©
.flex--center {
	justify-content: center;
}
.flex--right {
	justify-content: flex-end;
}
.flex--top {
	align-items: flex-start;
}
.flex--middle {
	align-items: center;
}
.flex--bottom {
	align-items: flex-end;
}

.grid {
	display: grid;
	grid-column-gap: @colGap;
	
	& > * {
		min-height: 0;
		min-width: 0;
	}
	
	@media @mediaQuery__md {
		grid-column-gap: @colGap*0.5;
	}
}
.grid--setAreas {
	& > *:nth-child(1){
		grid-area: a;
	}
	& > *:nth-child(2){
		grid-area: b;
	}
	& > *:nth-child(3){
		grid-area: c;
	}
	& > *:nth-child(4){
		grid-area: d;
	}
	& > *:nth-child(5){
		grid-area: e;
	}
	& > *:nth-child(6){
		grid-area: f;
	}
	& > *:nth-child(7){
		grid-area: g;
	}
	& > *:nth-child(8){
		grid-area: h;
	}
	& > *:nth-child(9){
		grid-area: i;
	}
	& > *:nth-child(10){
		grid-area: j;
	}	
}
.grid--cols-8 {
	grid-template-columns: repeat(8, 1fr);
}
.grid--cols-12 {
	grid-template-columns: repeat(12, 1fr);
}

.grid--appTemplate {
	grid-column-gap: 0;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
	
	@media @mediaQuery__xl {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__md {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(2, minmax(100vh, auto));
	}
	@media @mediaQuery__sm {}
	@media @mediaQuery__xs {}
}
.grid--greenCells {
	grid-column-gap: 2.5vw;
	grid-row-gap: 2.5vw;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
}
.grid--withAreas {
	.grid--setAreas;	
}

/*
.grid--LuMainHeader {
	display: flex;
	
	@media @mediaQuery__md {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: @colGap;
		grid-template-columns: 1fr 3fr;
	}		
	@media @mediaQuery__sm {}
}
.grid--LuPageHeader {
	grid-column-gap: @colGap;
	grid-template-columns: repeat(5, 1fr);
	
	@media @mediaQuery__dt {
		grid-template-columns: 1fr 1fr;
	}		
	@media @mediaQuery__md {
		grid-template-columns: 1fr 2fr;
	}		
	@media @mediaQuery__sm {
		grid-template-columns: 5fr;
	}
}
.grid--LuProductTeasers {
	grid-column-gap: @colGap;
	grid-template-columns: repeat(4, 1fr);
	grid-row-gap: @colGap*2;
	
	@media @mediaQuery__lg {
		grid-template-columns: repeat(5, 1fr);				
	}		
	@media @mediaQuery__md {
		grid-template-columns: repeat(3, 1fr);
	}		
	@media @mediaQuery__sm {
		grid-template-columns: repeat(2, 1fr);
	}
}
.grid--LuCartRow-products {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem 2rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem 1.5rem;			
	}		
}
.grid--LuCartRow-productsSum {
	grid-template-columns: 2rem 1fr 5rem 6rem 2rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4.5rem 1.5rem;	
	}		
}
.grid--LuCartRow-finishOrder {
	grid-template-columns: 8rem 1fr;
}
.grid--LuCartRow-shipping {
	grid-template-columns: 2rem 1fr 12rem 6rem;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1fr 12rem 4.5rem; 
	}
}
.grid--LuCartRow-payment {
	grid-template-columns: 2rem 1fr 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1fr; 
	}
}
.grid--LuCartRow-billingAndDeliveryAddresses {
	grid-template-columns: 10rem 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 6rem 1fr; 
	}
}
.grid--LuCartRow-finishingCustomer {
	grid-template-columns: repeat(3, 1fr);
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;
	}
}
.grid--LuCartRow-finishingProducts {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem;
	}			
}
.grid--LuCartRow-finishingProductsSum {
	grid-template-columns: 2rem 1fr 3rem 9.5rem 4rem 7rem;				
}
.grid--LuCartRow-finishingShipping {
	grid-template-columns: 2rem 1fr 6rem 5rem 6rem;	
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4rem 2.5rem 4.5rem;
	}		
}
.grid--LuCartRow-finishingSum {
	grid-template-columns: 2rem 1fr 5rem 6rem; 
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr 4.5rem;
	}
}
.grid--LuCartRow-finishingAccept {
	grid-template-columns: 2rem 30rem 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1.5rem 1.5fr;
	}
}
.grid--LuCartRow-thankYou {
	grid-template-columns: 2fr 1fr;
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;	
	}	
}
.grid--LubokCart-shoppingInfos {
	grid-template-columns: repeat(3, 1fr);
	
	@media @mediaQuery__md {
		grid-template-columns: 1fr;	
	}	
}
*/

.height {}
.height--pageHeader {}
.height--navRow {}

.hide {}
.hide--sm {
	@media @mediaQuery__xs { display: none; }
	@media @mediaQuery__sm { display: none; }
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
}

.hSpace {}

.hyphenate {
	hyphens: auto;		
}

.icon {}
.icon--lense {}
.icon--triangle {}

.inline {
	display: inline;
}
.inlineBlock {
	display: inline-block;
}

.link {
	color: inherit;
	text-decoration: none;
}

.maxWidth {}

.minHeight {} 
.minHeight--100vh {
	min-height: 100vh;
}

.relative {
	position: relative;
}

.rotate {
	transition: transform 0.25s ease;		
}
.rotate--180 {
	transform: rotate(180deg);
}

.shadow {}

.spinner {}
.spinner--sizeDefault {
	width: 1rem;
	height: 1rem;
}
.spinner--sizeMedium {
	height: 2rem;
	width: 2rem;
}

.sticky {
	position: sticky !important;
	top: 0;
}

.todo {
	.outline( red );
}

.vSpace {}
.vSpace--cardsPageHeader {
	padding-bottom: 0.5em;
}
.vSpace--cardsPageBody {
	padding-top: 3rem;		
	padding-bottom: 0.5em;		
}
.vSpace--rowPageHeader {
	padding-top: 0.5em;
	padding-bottom: 0.6em;
}
.vSpace--rowPageBody {
	padding-top: 1em;
	padding-bottom: 1em;
}

.view {}
	
.width {}
.width--100 { 
	width: 100%; 
}
