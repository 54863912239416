





































































































































































































@import "../less/vars.less";
//@import "../less/mixins.less";
//@import (reference) "../less/atoms.less";


.DbGreenWrapper {
	@strokeWidth : 3px;
	@padding : 1.5rem;
	
	position: relative;
	background-color: @swatches[green];
	
	&__inner {}
	
	&__layout--cards {
		width: 100%;
		
		.DbGreenWrapper__cards {
			//outline: 1px solid red;
			
			position: relative;
			padding: @padding;
			width: 100%;
			height: 100%;
		}
		
		/*
		.DbGreenWrapper__cardList {
			transform: translateY( 0.3em );
		}
		.DbGreenWrapper__cardListItem {}
		.DbGreenWrapper__cardListItemLink {
			display: block;
			padding: 0.1em 0;
		}
		*/
	
		.stroke {
			//display: none;
			position: absolute;
			border: @strokeWidth/2 solid @swatches[yellow];
		}
		.stroke--h {
			top: calc( 50% - @strokeWidth/2 );
			left: @padding; right: @padding;
		}
		.stroke--v {
			left: calc( 50% - @strokeWidth/2 );
			top: @padding; bottom: @padding;
		}
	
		.DbPageWrapper {
			//display: none;
			
			position: absolute;
			top: 0; left: 0; 
			width: 100%; height: 100%;
			//background-color: fade( @swatches[green], 75 );
			background-color: fade( @swatches[green], 100 );
			
		}
		.DbPageWrapper__closeBtn {
			//outline: 1px solid red;
			
			position: relative;
			color: transparent;
			
			&::before, &::after {
				position: absolute;
				top: 0.5em; right: 0;
				height: @strokeWidth*1.5;
				width: 1em;
				background-color: @swatches[yellow];
				content: "";
			}
			&::before {
				transform: rotate( 45deg );
			}
			&::after {
				transform: rotate( -45deg );
			}
		}
		
		.DbPageWrapper__inner {
			padding: @padding;
			overflow-y: auto;
			overflow-x: hidden;				
			height: 100%;
		}
	}
	&__layout--rows {				
		justify-content: space-between;
	}
	
	@media @mediaQuery__md {
		padding: 1.2rem 1rem;
	}
	
	//-webkit-text-stroke: 0.02em red;
}

.contentModule {
	margin-bottom: 1.5rem;
	max-width: 100%;
}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
