































































@import "../less/vars.less";
//@import "../less/mixins.less";
//@import (reference) "../less/atoms.less";

.DbImage {
	background-color: @swatches[yellow];
}

@media @mediaQuery__xl {}
@media @mediaQuery__lg {}
@media @mediaQuery__dt {}
@media @mediaQuery__md {}
@media @mediaQuery__sm {}
@media @mediaQuery__xs {}
