


























































































































































































































































.mhImage {
	position: relative;
}
.mhImage__debugInfos {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	overflow: auto;
	
	// label with debug infos
	[showborders4] &:before {
		position: absolute;
		top: 0; left: 0;
		content: attr(data-elmWidth) " × " attr(data-elmHeight) "\a" "checkBy: " attr(data-checkSizeBy) "\a" "bestSize: " attr(data-bestSizeName) ;
		white-space: pre-line;
		background-color: yellow;
		color: fade(black, 75);
		padding: 0.3em;
		font-size: 11px;
		line-height: 1.2em;
	}
	
	pre {
		background-color: fade(yellow, 50);			
	}
}
.mhImage__image {
	width: 100%;
	height: inherit;
	background-repeat: no-repeat;
	background-position: center;
}
.mhImage__image--mode-img {
	background-size: cover;		
}
.mhImage__image--mode-cover {
	position: absolute;
	top: 0; left: 0;
	height: 100%; 
	background-size: cover;
}
.mhImage__image--mode-contain {
	position: absolute;
	top: 0; left: 0;
	height: 100%; 
	background-size: contain;
}
